.header-shadow {
	background: #fff;
	height: 65px;
	display: flex;
	align-items: center;
	width: 100%;
	background-size: cover;
	-webkit-box-shadow: 2px 10px 20px -10px rgba(153, 148, 153, 1);
	-moz-box-shadow: 2px 10px 20px -10px rgba(153, 148, 153, 1);
	box-shadow: 2px 10px 20px -10px rgba(153, 148, 153, 1);

	border-bottom: #d5d9de 1px solid;

	color: #ffff;
	.btn-header {
		border-radius: 3px;
		font-weight: bold;
		cursor: pointer;

		color: #ffff !important;
		padding: 6px;
		&:hover {
			transition: all ease-in 0.2s;
			opacity: 0.8;
		}
	}
	.btn-header-login {
		margin-left: 10px;
		border-radius: 3px;

		cursor: pointer;
		font-weight: bold;
		color: #ffff !important;
		padding: 6px 12px;
		&:hover {
			transition: all ease-in 0.2s;
			opacity: 0.8;
		}
	}
}
