.social-sidebar {
  transition: all 2s ease-in;
	border: solid 1px #e1dddd;
	height: fit-content;

	margin-top: 10px;
	border-radius: 4px;
	background: #fff;
	width: 100%;
	padding: 20px;

	.user-data {
		display: flex;
		flex-direction: column;
		gap: 15px;

		&__img {
			width: 30px;
			height: 30px;
			border-radius: 100%;
		}

		&__text {
			font-size: 13px;
			font-weight: 500;
		}

		span {
			font-size: 30px !important;
		}

    .user-data__initial {

      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 50%;
      background-color: #ccc; 
      font-size: 24px; 
      font-weight: bold;
      color: #fff;
    }
	}
 

	@media (max-width: 980px) {
    transition: all 0.5 ease-in-out;
    display: flex;
    flex-direction: column;
    align-items: center;
    min-width: auto !important;
    max-width: 100px;

    .user-container-menu {
      width: fit-content !important;
    }

    .register {
      display: none;
    }

    .icon-dsc {
      padding: 0px !important;
    }

    .user-data__text {
      width: fit-content !important;
			gap: 0px !important;
			display: none !important;
			padding: 0px !important;
    }

    .bread-crumb {
      display: none;
    }
	}

  @media (max-width: 500px) {
    display: none !important;
    min-width: auto !important;
    max-width: 100px;

    .user-container-menu {
      width: fit-content !important;
    }

    .register {
      display: none;
    }
  
    .icon-dsc {
      padding: 0px !important;
    }
  
    .user-data__text {
      width: fit-content !important;
			gap: 0px !important;
			display: none !important;
			padding: 0px !important;
    }
  
    .bread-crumb {
      display: none;
    }

	}
}

@media (max-width: 1200px) {
  .profiles-container {
    display: none !important;
  }
}