.dsc_input {
	label {
		font-weight: 500;
		font-size: 14px;
		line-height: 20px;
		color: #233043;
	}

	.required {
		color: red;
		margin-top: -5px;
	}

	input {
		padding: 10px 12px 10px 12px;
		border: solid 2px #edeff1;
		border-radius: 4px;
	}
}
