.toast {
	border-radius: 4px;
	.close {
		cursor: pointer;
		
	}

  .bar-percentage {
    min-height: 3px;
    background: #0654DF;
  }
}
