.documents {
  .image-card {
    transition: 0.2s ease-in;
    border-radius: 4px;
    &:hover {
      transition: 0.2s ease-in;
      cursor: pointer;

      background: #000;
      filter: blur(1px);
      filter: brightness(0.5);
    }
  }

  .container-modal {
    border-radius: 10px;
    min-height: 600px;
  }

  .modal-container {
    width: 86%;
    max-width: 1200px;
  }

  .left-modal {
    border-top-right-radius: 5px;
    border-bottom-right-radius: 5px;
  
  }
  
  .side-color {
    background-color: rgb(19 19 19 / 1);
  }

  .card-info {
  width: 148px;
  background: #2c2c2c;
  padding: 10px;
  border-radius: 4px;
}

.label {
  padding: 4px 0px;
  color: #6f6f6f;
  font-size: 15px;
  font-weight: 300;
}
  
  .right-modal {
    border-top-left-radius: 5px;
    border-bottom-left-radius: 5px;
  }
  
  .overlay-modal-image {
    z-index: 999999;
    position: fixed;
    width: 100%;
    left: 0;
    height: 100%;
    top: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    background: #232224bd;
  }
  
  .post-image {}
}