.home-social {

	padding-top: 0 !important;
	margin-top: 0px !important;
	max-width: 700px !important;
	
	width: 100%;
	display: flex;
	flex-direction: column;
	margin: auto;
	$base-color: #2e86c1;
	$light-base-color: #aed6f1;
	$dim-color: #eaecee;
	$dimmer-color: #d6dbdf;

	input,
	button,
	textarea {
		outline-style: none;
	}

	.image-block-style {
		height: -webkit-fill-available;
	}

	p {
		margin: 5px 0 10px 0;
	}

	.muted {
		opacity: 0.4;
	}

	.float-right {
		float: right;
	}

	.float-left {
		float: left;
	}

	.content {
		display: flex;
		width: 70%;
		margin: auto;
		//height: 100vh;
	}

	.ql-tollbar {
		border-radius: 10px !important;
	}

	.ql-container {
		border-left: none !important;
		border-right: none;
		border-bottom: none;
	}
	.inner {
		font-size: 21px;
	}

	.left-menu {
		flex: 0.9;
		padding: 10px 20px 10px 20px;

		.menu {
			list-style: none;

			li {
				padding: 15px 0 15px 0;
				cursor: pointer;
				a {
					border-radius: 20px;
					text-decoration: none;
					font-weight: 400;
					font-size: 20px;

					.far,
					.fas {
						width: 30px;
					}
				}
			}
		}
	}

	.timeline {
		flex: 2;
		width: -webkit-fill-available !important;

		.header {
			border-bottom: 1px solid rgba(255, 0, 0, 0.1);
		}

		.avatar {
			min-width: 50px;
			height: 50px;
			border-radius: 50%;
		}

		.tweet-box {
			height: 108px;
			display: flex;
			.tweet-textarea {
				flex-grow: 100;
				border: none;
				padding: 10px;

				font-size: 18px;
			}
		}

		.tweets {
			padding-bottom: 30px;

			.tweet {
				background: #fff;
				padding: 10px;
				display: flex;
				border-bottom: 1px solid rgba(255, 0, 0, 0.1);

				overflow: hidden;
				.tweet-message {
					padding: 0 10px 0 10px;

					.tweet-image {
						width: 400px;
						border-radius: 20px;
					}
				}
			}
		}
	}

	.right-menu {
		flex: 1.1;
		padding: 0px 20px 10px 20px;

		::placeholder {
			/* Most modern browsers support this now. */
			color: #ffff;
		}
		.trends-menu {
			border-radius: 4px !important;
			background-color: #ffff;
			color: #0000 !important;

			font-weight: bold;
			border-radius: 3x;
			//margin: 15px 0 15px 0;

			.header {
				padding: 15px 20px 15px 20px;
				font-weight: bold;
				border-bottom: 1px solid $dimmer-color;
			}
			.footer {
				padding: 10px 20px 10px 20px;
				color: black;
				font-weight: bold;
			}

			.item {
				border-bottom: 1px solid $dimmer-color;
				padding: 10px 20px 10px 20px;
				font-weight: bold;
				line-height: 1.5;

				.trend-topic,
				.trend-tweets {
					display: block;
					font-weight: normal;
				}
			}
		}
	}

	.big-button {
		width: 100%;
		padding: 16px;
		border-radius: 30px;
		background: linear-gradient(90deg, rgba(111, 33, 108, 1) 0%, rgba(101, 24, 106, 1) 51%, rgba(30, 2, 45, 1) 100%);
		color: white;
		border: 0;
		font-weight: bold;
		opacity: 0.9;
		box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;

		.fas {
			display: none;
		}
	}

	.big-button:hover {
		opacity: 1;
	}

	.search-field {
		padding: 12px 15px 12px 15px;
		border-radius: 3px;
		border: 0;
		color: #0000;
		font-weight: bold;
		background: #ffff;
		width: 100%;
		input[type='search']::placeholder {
			color: gray; /* Ou a cor exata de cinza que você deseja, como #888 ou rgb(128, 128, 128) */
		}
		input {
			padding: 4px;
			color: black !important;
		}
	}

	// mobile view
	@media (max-width: 600px) {
		.left-menu {
			display: none;
		}
	}

	// tablet view
	@media (max-width: 900px) {
		.right-menu {
			display: none;
		}
	}

	// desktop view
	@media (max-width: 1200px) {
		.left-menu {
			flex: 0.2;
			text-align: center;

			.menu {
				padding: 10px 0 10px 0;

				li {
					a {
						.far,
						.fas {
							width: auto;
							font-size: 20px;
						}
						.label {
							display: none;
						}
					}
				}
			}
			.big-button {
				width: auto;

				.label {
					display: none;
				}
				.fas {
					display: block;
				}
			}
		}
	}

	.social-tab {
		width: 100%;
		display: flex;
		align-items: center;
		justify-content: center;
		border-bottom: solid 1px #e1dddd;
		height: 57px;
	}
}

.home-container {
  position: relative;
  overflow: visible;
}

.show-preview-movie {
	overflow: hidden;
	margin-top: 5px;
	display: flex;
	flex-direction: row;
	gap: 2px;
	align-items: center;
	width: 100% !important;
	height: 200px !important;
	div {
		width: 100% !important;
		height: 200px !important;
	}
	iframe {
		width: 100% !important;
		height: 200px !important;
	}
}
