@import url('https://fonts.googleapis.com/css2?family=Be+Vietnam+Pro:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');

:root {
	--c-text-primary: #282a32;
	--c-text-secondary: #686b87;
	--c-text-action: #404089;
	--c-accent-primary: #434ce8;
	--c-border-primary: #eff1f6;
	--c-background-primary: #ffffff;
	--c-background-secondary: #fdfcff;
	--c-background-tertiary: #ecf3fe;
	--c-background-quaternary: #e9ecf4;
}

body {
	line-height: 1.5;
	min-height: 100vh;

	background-color: #eceff1;
	color: var(--c-text-primary);
}

img {
	display: block;
	max-width: 100%;
}

:focus {
	outline: 0;
}

.responsive-wrapper {
	width: 90%;
	max-width: 1280px;
	margin-left: auto;
	margin-right: auto;
}

.header {
	display: flex;
	align-items: center;
	height: 80px;
	border-bottom: 1px solid var(--c-border-primary);
}

.header-content {
	display: flex;
	align-items: center;
	& > a {
		display: none;
	}
	@media (max-width: 1200px) {
		justify-content: space-between;
		& > a {
			display: inline-flex;
		}
	}
}

.header-logo {
	margin-right: 2.5rem;
}

.header-navigation {
	display: flex;
	flex-grow: 1;
	align-items: center;
	justify-content: space-between;
	@media (max-width: 1200px) {
		display: none;
	}
}

.header-navigation-links {
	display: flex;
	align-items: center;
}

.header-navigation-actions {
	display: flex;
	align-items: center;
	& > .avatar {
		margin-left: 0.75rem;
	}
	& > .icon-button + .icon-button {
		margin-left: 0.25rem;
	}

	& > .button + .icon-button {
		margin-left: 1rem;
	}
}

.button {
	font: inherit;
	color: inherit;
	text-decoration: none;
	display: inline-flex;
	align-items: center;
	justify-content: center;
	padding: 0 1em;
	height: 40px;
	border-radius: 8px;
	line-height: 1;
	border: 2px solid var(--c-border-primary);
	color: var(--c-text-action);
	font-size: 0.875rem;
	transition: 0.15s ease;
	background-color: var(--c-background-primary);

	i {
		margin-right: 0.5rem;
		font-size: 1.25em;
	}

	span {
		font-weight: 500;
	}

	&:hover,
	&:focus {
		border-color: var(--c-accent-primary);
		color: var(--c-accent-primary);
	}
}

.icon-button {
	font: inherit;
	color: inherit;
	text-decoration: none;
	display: inline-flex;
	align-items: center;
	justify-content: center;
	width: 40px;
	height: 40px;
	border-radius: 8px;
	color: var(--c-text-action);
	transition: 0.15s ease;
	i {
		font-size: 1.25em;
	}

	&:focus,
	&:hover {
		background-color: var(--c-background-tertiary);
		color: var(--c-accent-primary);
	}
}

.avatar {
	display: inline-flex;
	align-items: center;
	justify-content: center;
	width: 50px;
	height: 44px;
	border-radius: 50%;
	overflow: hidden;
}

.main {
	padding-top: 3rem;
}

.main-header {
	display: flex;
	flex-wrap: wrap;
	align-items: center;
	justify-content: space-between;

	h1 {
		font-size: 1.75rem;
		font-weight: 600;
		line-height: 1.25;
		@media (max-width: 550px) {
			margin-bottom: 1rem;
		}
	}
}

.search {
	position: relative;
	display: flex;
	align-items: center;
	width: 100%;
	max-width: 340px;
	input {
		font: inherit;
		color: inherit;
		text-decoration: none;
		display: inline-flex;
		align-items: center;
		justify-content: center;
		padding: 0 1em 0 36px;
		height: 40px;
		border-radius: 8px;
		border: 2px solid var(--c-border-primary);
		color: var(--c-text-action);
		font-size: 0.875rem;
		transition: 0.15s ease;
		width: 100%;
		line-height: 1;

		&::placeholder {
			color: var(--c-text-action);
		}

		&:focus,
		&:hover {
			border-color: var(--c-accent-primary);
		}
	}

	button {
		display: inline-flex;
		align-items: center;
		justify-content: center;
		border: 0;
		background-color: transparent;
		position: absolute;
		left: 12px;
		top: 50%;
		transform: translateY(-50%);
		font-size: 1.25em;
		color: var(--c-text-action);
		padding: 0;
		height: 40px;
	}
}

.horizontal-tabs {
	margin-top: 1.5rem;
	display: flex;
	align-items: center;
	overflow-x: auto;
	@media (max-width: 1000px) {
		scrollbar-width: none;
		position: relative;
		&::-webkit-scrollbar {
			display: none;
		}
	}

	a {
		display: inline-flex;
		flex-shrink: 0;
		align-items: center;
		height: 48px;
		padding: 0 0.25rem;
		font-weight: 500;
		color: inherit;
		border-bottom: 3px solid transparent;
		text-decoration: none;
		transition: 0.15s ease;
		&:hover,
		&:focus,
		&.active {
			color: var(--c-accent-primary);
			border-bottom-color: var(--c-accent-primary);
		}

		& + * {
			margin-left: 1rem;
		}
	}
}

.content-header {
	display: flex;
	flex-wrap: wrap;
	align-items: center;
	justify-content: space-between;
}

.content-header-intro {
	h2 {
		font-size: 1.25rem;
		font-weight: 600;
	}

	p {
		color: var(--c-text-secondary);
		margin-top: 0.25rem;
		font-size: 0.875rem;
		margin-bottom: 1rem;
	}
}

.content-header-actions {
	a:first-child {
		@media (min-width: 800px) {
			display: none;
		}
	}
}

.content {
	display: flex;
	align-items: flex-start;
}

.content-panel {
	display: none;
	max-width: 280px;
	width: 25%;
	padding: 2rem 1rem 2rem 0;
	margin-right: 3rem;
	@media (min-width: 800px) {
		display: block;
	}
}

.vertical-tabs {
	display: flex;
	flex-direction: column;
}

.content-main {
	padding-top: 2rem;
	padding-bottom: 6rem;
	flex-grow: 1;
}

.card-grid {
	display: grid;
	grid-template-columns: repeat(1, 1fr);
	column-gap: 1.5rem;
	row-gap: 1.5rem;
}
