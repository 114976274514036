.side-nav-profiles {

	//border-left: solid 3px #e1dddd;
	width: 100%;
	&__header {
		height: 57px;
		//border-bottom: solid 3px #E1DDDD;
	}
	.container-profiles {
		margin-top: 10px;
		background: #fff;
		border: solid 1px #e1dddd;
		border-radius: 10px;
	}
}
