.overlay {
	position: absolute;
	width: 100vw;
	height: 100vh;
	background: #261818;
	opacity: 60%;
	z-index: 2;
}

.right-divider {
  border-right: solid 1px #dbdbdb;
}

.social-svg {
	svg {
		width: 20px;
		height: 20px;
		margin-right: 5px;
	}
}

.custom-icon {
  span {
    color: rgb(36 38 39 / 80%);
  }
}

.h-webkit {
  height: -webkit-fill-available;
}

.is-selected-option {
  background: #e8e8ff;
}

.is-soon {
  background: #BABABA;
  display: flex;
  height: 100%;
  align-items: center;
  justify-content: center;
  border-radius: 4px;
  cursor: not-allowed;
  .option {
    &:hover {
      background: #BABABA !important;
      cursor: not-allowed !important;
    }
  }
}

.line {
	min-height: 2px;
	width: 100%;
	position: absolute;
	background: #ededed;
}

.social-ic {
	width: 24px;
	height: 24px;
}

.option {
  padding: 5px;
  &:hover {
    cursor: pointer;
    background: #ededed;
  }
}

.w-fill {
  width: -webkit-fill-available;
}
