.help-component {
	.next-bnt {
		padding: 4px;

		color: #000;
		font-weight: 600;
	}
	.close-btn {
		padding: 4px;
		background: #911b1b;
		color: #fff;
		font-weight: 600;
	}
}
