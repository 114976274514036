fieldset {
	border-color: #fff !important;
}

.data__initial {

  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  background-color: #ccc; /* Cor de fundo para o círculo */

  font-weight: bold; /* Negrito para a inicial */
  color: #fff; /* Cor do texto */
}