.feed {
  .quill {
		overflow-y: auto !important;
		max-height: 280px;
  }
  
  .answer-border {
    border-top: solid 3px #e1dddd;
    border-bottom: solid 3px #e1dddd;
  }
  
  .body-overflow {
    max-height: 110px;
    overflow-y: auto;
  
    &::-webkit-scrollbar {
      width: 5px;
    }
    
    &::-webkit-scrollbar-thumb {
      background-color: var(--icon-neutral);
      border-radius: 3.5px;
    }
  
    &::-webkit-scrollbar-track-piece {
      margin: 0px;
    }
  }

  .is-repost {
    border: solid 1px #ededed;
    padding: 5px;
    border-radius: 4px;
  }
}
